const _ = require('lodash')
const {flagCountryIconType} = require('./constant')

const flagCountryIconTypeTranslation = {
    rounded: 'corner',
    circle: 'round',
    square: 'square'
}

module.exports = {

    /**
     * get the file name of country flag icon.
     * @param flagCountry {string} the country name of flag
     * @param iconType {string} the type which listed in constant.flagCountryIconType
     */
    getFlagIconUrl: _.curry((domainUrl, flagForm, countryCode) => {
        if (!flagCountryIconType[flagForm]) {
            throw new Error(`Unsupported flag form provided (${flagForm}), use one of ${_.values(flagCountryIconType)}`)
        }
        return `${domainUrl}/assets/flags/${flagCountryIconTypeTranslation[flagForm]}/${countryCode}.svg`
    })
}
